import { useState } from 'react'
import TableList from '../TableListViewComponent'
import { saveAs } from 'file-saver'
import OdooDatabase from '../../data/odoo'
import Button from '@material-ui/core/Button'
import UploadDialog from './UploadDlg'
import Loading from '../LoadingComponent'
import { UploadedFile } from './UploadDlg'


type AttachmentsProperties = {
    workorder_id: number;
    onError(msg: string): void;
}

export default function Attachments(props: AttachmentsProperties){
    const [uploadDlgOpen, setUploadDlgOpen] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [attachmentsKey, setAttachmentsKey] = useState(0)
    const downloadClick = async (attachment_id: number) => {
        try{
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.execute_kw('ir.attachment', 'read', [[[attachment_id], ['name', 'datas']]])
            let buff = Buffer.from(data[0].datas, 'base64')
            let blob = new Blob([buff], {type: 'application/pdf'})
            saveAs(blob, data[0].name)
        }
        catch(err: any){
            console.log('Error', err)
            props.onError(err.faultString)
        }
    }

    const renderAttachment = (field: string, datum: any) => {
        return (<span className="tech_app_link" onClick={(event: any) => {downloadClick(datum.id); event.preventDefault()}}><span>{datum[field]}</span></span>)
    }

    const isPDF = (data: string) => {
        let byteString = atob(data.split(',')[1])
        return byteString.length > 4 && byteString.charCodeAt(0) ==0x25 && byteString.charCodeAt(1) == 0x50 &&
		    byteString.charCodeAt(2) == 0x44 && byteString.charCodeAt(3) == 0x46
    }

    const onFilesOpen = async (files: UploadedFile[]) => {
        setUploadDlgOpen(false);
        setUpdating(true)
        for(let i = 0; i < files.length; i++) {
            const dataUri = files[i].data;
            const name = files[i].name;
            if(isPDF(dataUri)){
                try{
                    const data = dataUri.split(',')[1]
                    const odoo = OdooDatabase.getInstance()
                    await odoo.create('ir.attachment', {
                        name: name,
                        datas: data,
                        mimetype: 'application/pdf',
                        file_type: 'application/pdf',
                        res_model: 'fieldservice.workorder',
                        res_id: props.workorder_id,
                    })
                }
                catch(err: any){
                    console.log('Error', err.faultString)
                    props.onError(err.faultString)
                }
            }
            else
                props.onError('Only pdf files are allowed')
        }
        setAttachmentsKey(1 - attachmentsKey)
        setUpdating(false)
        console.log('updated to db');
    }

    return (
        <>
        <UploadDialog open={uploadDlgOpen} title="Upload document" onClose={()=>{setUploadDlgOpen(false)}} extensions=".pdf" onFilesOpen={onFilesOpen} multiple={true}/>
        <Loading loading={updating}/>
        <TableList key={`attachments_list_${attachmentsKey}`}
            model="ir.attachment"
            fields={['name']}
            elements={
                [
                    {field: 'name', header: 'Name', onRender: renderAttachment},
                ]
            }
            domain={[['res_id','=', props.workorder_id], ['res_model', '=', 'fieldservice.workorder']]}
            pagination={true}
            pageSize={5}
    />
    <div className="wo_app_buttons wo_app_centered_buttons">
        <Button
                variant="contained"
                color="primary"
                onClick={()=>{setUploadDlgOpen(true)}}
        >Upload</Button>
    </div>
    </>
    )
}